.SetupNames .gameActive {
  color: red;
  font-weight: bold;
  font-size: 1.1em;
}
.SetupNames .list-move {
  transition: transform 1s;
}
.SetupNames p.warning {
  color: red;
}
.SetupNames .list {
  margin: 20px 0;
}
.SetupNames .list .isDup {
  display: inline-block;
  width: 100px;
  color: red;
}
.SetupNames .list input[type="text"] {
  width: 5em;
  margin: 0 20px 0 10px;
  position: relative;
}
.SetupNames .list input[type="text"].missing {
  box-shadow: 0 0 3px 1px red;
}
.SetupNames .list input[type="text"].teamName {
  width: 2em;
}
.SetupNames .list .num {
  display: inline-block;
  width: 20px;
  text-align: right;
  margin: 0 5px 0 5px;
  font-size: 75%;
  color: grey;
}
.SetupNames .list label {
  display: inline-block;
  margin: 0 5px;
}
.SetupNames [data-title] {
  position: relative;
}
.SetupNames [data-title]::before {
  content: attr(data-title);
  position: absolute;
  top: -14px;
  left: 10px;
  font-size: 60%;
  color: grey;
}
.SetupNames .guest {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 15px;
  padding: 5px 0;
}
.SetupNames .guest .part2 {
  display: flex;
}
.SetupNames .guest input {
  margin: 0;
}
.SetupNames .guest input.missing {
  box-shadow: 0 0 3px 1px red;
}
.SetupNames .forgetBtn {
  width: 90px;
}
.SetupNames button.moveMe {
  cursor: ns-resize;
}
.SetupNames button.moveMe,
.SetupNames button.remove {
  margin: 0;
  padding: 0;
  display: inline-block;
  box-shadow: none;
}
.SetupNames button.moveMe i,
.SetupNames button.remove i {
  vertical-align: middle;
}
.SetupNames button.moveMe:hover,
.SetupNames button.remove:hover {
  background-color: #fcfcfc;
}
.itemHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 15px;
  padding: 5px 0;
  box-sizing: border-box;
}
.itemHolder.active + .itemHolder.inactive {
  border-top: 3px double red;
  padding-top: 30px;
  position: relative;
}
.itemHolder.active + .itemHolder.inactive::before {
  content: "Sitting out this round";
  position: absolute;
  top: -1px;
  left: 0;
  font-size: 75%;
  font-style: italic;
  color: red;
}
.itemHolder > div {
  white-space: nowrap;
  margin: 2px;
  display: flex;
  align-items: center;
}
.itemHolder .nameWhileMoving {
  display: none;
}
.itemHolder.moving {
  text-align: center;
  background-color: lightblue;
  margin: 5px auto;
}
.itemHolder.moving > * {
  display: none;
}
.itemHolder.moving > *.nameWhileMoving {
  display: block;
}
