























































































































































































.SetupGame {
  .questions {
    margin: 1em 0;
    text-align: left;
    display: flex;
    justify-content: space-around;
    > div {
      max-width: 60%;
      .el-checkbox__label {
        white-space: normal;
        vertical-align: top;
      }
    }
    .BooleanSwitch {
      > span {
        margin-right: 0.5em;
      }
    }
  }
  .summary {
    font-style: italic;
  }
  .el-checkbox__label {
    font-size: 1rem;
  }
}
