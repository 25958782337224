











































































































































.Stage1 {
  .CardHolder.focused div.Card {
    background-color: lightgreen;
  }
}
