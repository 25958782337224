.ScoreSheet_Pinochle .dialogFooter {
  text-align: right;
}
.ScoreSheet_Pinochle .NoScores {
  font-size: 1.1em;
  margin: 2em auto;
  text-align: center;
}
.ScoreSheet_Pinochle table {
  border-collapse: collapse;
  margin: 0 auto;
}
.ScoreSheet_Pinochle td,
.ScoreSheet_Pinochle th {
  padding: 5px 4px 5px 5px;
  text-align: center;
  white-space: nowrap;
}
.ScoreSheet_Pinochle th.bottom {
  vertical-align: bottom;
}
.ScoreSheet_Pinochle th.suit {
  padding: 3px 0 0 3px;
}
.ScoreSheet_Pinochle th.suit img {
  height: 18px;
}
.ScoreSheet_Pinochle .bid {
  border-right: 1px solid black;
}
.ScoreSheet_Pinochle .rank {
  border-right: 1px solid black;
}
.ScoreSheet_Pinochle .info {
  background-color: #f9f9f9;
}
.ScoreSheet_Pinochle .info span {
  font-size: 75%;
  padding-left: 3px;
}
.ScoreSheet_Pinochle .info2 {
  background-color: #f2f2f2;
  border-right: 1px solid #aaa;
}
.ScoreSheet_Pinochle .info span,
.ScoreSheet_Pinochle .info2 span {
  font-size: 75%;
  color: #999;
  padding: 0 2px;
}
.ScoreSheet_Pinochle .rank1 {
  background-color: #c1fcc1;
}
.ScoreSheet_Pinochle .bid.ok,
.ScoreSheet_Pinochle .got.ok {
  background-color: #e1ffe1;
}
.ScoreSheet_Pinochle .bid.viewer,
.ScoreSheet_Pinochle .got.viewer {
  background-color: #fff;
}
.ScoreSheet_Pinochle .bid.missed,
.ScoreSheet_Pinochle .got.missed {
  background-color: #ffebeb;
}
.ScoreSheet_Pinochle .red {
  color: #de2626;
}
.ScoreSheet_Pinochle .black {
  color: black;
}
.ScoreSheet_Pinochle .score {
  border-left: 1px solid #aaa;
  border-right: 1px solid black;
}
.ScoreSheet_Pinochle .score.played {
  background-color: #f9f9f9;
}
.ScoreSheet_Pinochle tr.failed .bid {
  background-color: #ffebeb;
  color: #de2626;
}
.ScoreSheet_Pinochle .bidWinner {
  background-color: #e1ffe1;
}
.ScoreSheet_Pinochle .bidWinner.failed {
  background-color: #ffebeb;
}
.ScoreSheet_Pinochle .topScore {
  background-color: #c1fcc1;
}
.ScoreSheet_Pinochle .name {
  border: 1px solid black;
}
.ScoreSheet_Pinochle .scores tbody tr {
  border-top: 1px solid grey;
}
.ScoreSheet_Pinochle tfoot tr:first-child {
  border-top: 3px double #000;
}
.ScoreSheet_Pinochle tfoot td,
.ScoreSheet_Pinochle tfoot th {
  padding: 2px 3px;
}
.ScoreSheet_Pinochle tfoot .rankLeftSide {
  text-align: right;
}
.ScoreSheet_Pinochle .rules {
  margin: 2em auto 0;
  width: 39em;
  text-align: left;
}
.ScoreSheet_Pinochle .summary {
  border: 1px solid grey;
  margin: 1em auto;
}
.ScoreSheet_Pinochle .summary thead {
  border-bottom: 1px solid grey;
}
.ScoreSheet_Pinochle .summary th {
  text-align: left;
  border-right: 1px solid grey;
}
.ScoreSheet_Pinochle .summary td:nth-child(2) {
  border-right: 1px solid grey;
}
.ScoreSheet_Pinochle .summary th,
.ScoreSheet_Pinochle .summary td {
  padding: 1px 5px;
}
.ScoreSheet_Pinochle .summary tbody tr {
  border-bottom: 1px solid lightgrey;
}
.ScoreSheet_Pinochle .summary tbody tr:last-child {
  border-bottom: 1px solid grey;
}
