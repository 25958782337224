.BidDisplay {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.BidDisplay .title {
  padding: 0 1em;
  white-space: normal;
}
.BidDisplay .title span {
  white-space: nowrap;
}
.BidDisplay i {
  font-size: 1.4rem;
  margin-top: 3px;
  font-weight: bold;
}
.BidDisplay i.el-icon-remove-outline {
  color: #e0e0e0;
}
.BidDisplay i.el-icon-circle-check {
  color: #4a993e;
}
.BidDisplay i.el-icon-circle-plus-outline {
  color: #d40600;
}
.BidDisplay .roundFinished i.el-icon-remove-outline {
  color: #ed9b9f;
}
.BidDisplay .el-steps {
  flex-wrap: wrap;
  justify-content: center;
}
.BidDisplay .el-step {
  margin: 0 0 3px 0;
  min-width: 24px;
}
.BidDisplay .el-step__title {
  line-height: normal;
}
.BidDisplay .el-step__line {
  display: none;
}
