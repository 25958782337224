.Stage2Pinochle {
  min-height: 100%;
  height: 100%;
  position: relative;
}
.Stage2Pinochle.next .myArea {
  border-color: #de2626;
}
.Stage2Pinochle .drawPile {
  position: relative;
  padding: 0.5em;
  width: 98px;
  height: 147px;
  border: 1px solid black;
}
.Stage2Pinochle .drawPile .CardHolder {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}
.Stage2Pinochle .seatingWithTable {
  flex-grow: 1;
  display: grid;
  min-height: 400px;
  gap: 3px 3px;
  grid-template-columns: minmax(0, 0.7fr) 2fr minmax(0, 0.7fr);
  grid-template-rows: auto minmax(330px, auto) 240px;
  grid-template-areas: "topLeft seatT1 topRight" "seatL1 table seatR1" "myArea myArea myArea";
}
.Stage2Pinochle .seatingWithTable button {
  align-self: center;
}
.Stage2Pinochle .table {
  grid-area: table;
  min-height: 320px;
  display: grid;
  gap: 1px 1px;
  grid-template-columns: 1fr 115px 1fr;
  grid-template-rows: 1.4fr auto 1.5fr auto;
  grid-template-areas: "tableL1 tableT1 tableR1" "tableL1 tableCenter tableR1" "tableL1 myPlay tableR1" "trumpSuit tableButtons leadSuit";
  position: relative;
  background: #e8f9e6;
  background-clip: padding-box;
  border: solid 5px transparent;
  border-radius: 25px;
}
.Stage2Pinochle .table:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -5px;
  border-radius: inherit;
  background: darkgreen;
}
.Stage2Pinochle .table.isViewer .seatingWithTable {
  grid-template-rows: auto minmax(320px, 1.3fr) auto;
}
.Stage2Pinochle .table .mainLogo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 40%;
  transition: all 0.5s;
}
.Stage2Pinochle .table .mainLogo img {
  height: 140%;
}
.Stage2Pinochle .table .cardArea {
  display: flex;
  align-items: center;
  position: relative;
}
.Stage2Pinochle .table .cardArea .CardHolder {
  display: block;
  margin-right: -96px;
}
.Stage2Pinochle .table .cardArea .CardHolder:last-child {
  margin-right: 5px;
}
.Stage2Pinochle .table .cardArea.left .meldDisplay {
  right: 30%;
}
.Stage2Pinochle .table .cardArea.right .meldDisplay {
  left: 30%;
}
.Stage2Pinochle .table .cardArea.top .meldDisplay {
  top: 37%;
}
.Stage2Pinochle .table .cardArea.bottom .meldDisplay {
  bottom: 0;
}
.Stage2Pinochle.players_4 .seatingWithTable {
  grid-template-areas: "topLeft seatT1 topRight" "seatL1 table seatR1" "myArea myArea myArea";
}
.Stage2Pinochle.players_4 .table {
  grid-template-rows: 1.4fr auto 1.5fr auto;
  grid-template-areas: "tableL1 tableT1 tableR1" "tableL1 tableCenter tableR1" "tableL1 myPlay tableR1" "trumpSuit tableButtons leadSuit";
}
.Stage2Pinochle.players_4.isViewer .seatingWithTable {
  grid-template-rows: auto minmax(200px, 1fr) auto;
}
.Stage2Pinochle.players_4.isViewer .table {
  grid-template-areas: "tableL1 tableT1 tableR1" "tableL1 tableCenter tableR1" "tableL1 tableB1 tableR1" "trumpSuit tableButtons leadSuit";
}
.Stage2Pinochle .p0table,
.Stage2Pinochle .seatB1 {
  display: none;
}
.Stage2Pinochle .tableButtons {
  grid-area: tableButtons;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 5px;
  z-index: 2;
}
.Stage2Pinochle .tableButtons button {
  white-space: nowrap;
}
.Stage2Pinochle .trumpSuit {
  grid-area: trumpSuit;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 3px;
}
.Stage2Pinochle .trumpSuit .trumpInfo {
  margin: -1rem 0 -0.5rem 10px;
  font-size: 2.1rem;
}
.Stage2Pinochle .leadSuit {
  grid-area: leadSuit;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 3px;
}
.Stage2Pinochle .leadSuit .trumpInfo {
  margin: -1rem 10px -0.5rem 0;
  font-size: 2.1rem;
}
.Stage2Pinochle.isViewer .cardArea.p0table {
  grid-area: tableB1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}
.Stage2Pinochle.isViewer .cardArea.p0table .meldDisplay {
  bottom: 3px;
  left: 50%;
  transform: translate(-50%);
}
.Stage2Pinochle.isViewer .smallCardsGroups .CardHolder {
  max-height: 40px;
}
.Stage2Pinochle.isViewer .seatB1 {
  grid-area: myArea;
}
.Stage2Pinochle.isViewer .myPlay {
  display: none;
}
.Stage2Pinochle.isViewer .myArea {
  display: none;
}
.Stage2Pinochle .tableCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: center;
  font-size: 1.7em;
  position: absolute;
  z-index: 1;
  left: 25%;
  right: 25%;
  width: auto;
  text-align: center;
  padding: 0.25em 0.5em 0.25em;
  overflow: visible;
  background: white;
  border: 2px solid grey;
  border-radius: 5px;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 #000000;
}
.Stage2Pinochle .tableCenter button {
  margin-top: 1em;
}
.Stage2Pinochle .myPlay {
  grid-area: myPlay;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}
.Stage2Pinochle .myPlay .meldDisplay {
  bottom: 3px;
  left: 50%;
  transform: translate(-50%);
}
.Stage2Pinochle .tableT1 {
  grid-area: tableT1;
  display: flex;
  justify-content: stretch;
  align-content: center;
  padding-top: 6px;
}
.Stage2Pinochle .tableT1 .bidNum {
  height: 100%;
}
.Stage2Pinochle .tableL1 {
  grid-area: tableL1;
}
.Stage2Pinochle .tableL1 .bidNum {
  padding-left: 8%;
  text-align: left;
  width: 100%;
}
.Stage2Pinochle .tableR1 {
  grid-area: tableR1;
}
.Stage2Pinochle .tableR1 .bidNum {
  padding-right: 10%;
  text-align: right;
  width: 100%;
}
.Stage2Pinochle .tableL1,
.Stage2Pinochle .tableL2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.Stage2Pinochle .tableR1,
.Stage2Pinochle .tableR2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Stage2Pinochle .myArea {
  grid-area: myArea;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 0 2px;
  border: lightblue 6px solid;
  background: #fbfbf9;
  border-radius: 5px;
}
.Stage2Pinochle .myArea .cardArea {
  margin-top: 20px;
  padding: 0 5px;
  height: 150px;
  align-items: center;
}
.Stage2Pinochle .myArea .suit_c + .suit_s,
.Stage2Pinochle .myArea .suit_s + .suit_c,
.Stage2Pinochle .myArea .suit_d + .suit_h,
.Stage2Pinochle .myArea .suit_h + .suit_d {
  margin-left: 5px;
}
.Stage2Pinochle .myArea .BidDisplay .title span + span {
  margin-left: 1em;
}
.Stage2Pinochle .seatArea {
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  align-content: center;
  padding: 5px 5px 0;
  border: lightblue 6px solid;
  background: #fbfbf9;
  border-radius: 5px;
}
.Stage2Pinochle .seatArea.next {
  border-color: #de2626;
}
.Stage2Pinochle .seatArea .name {
  text-align: left;
  font-weight: bold;
}
.Stage2Pinochle .seatArea .nameAndDealer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: top;
  align-items: center;
}
.Stage2Pinochle .seatArea .nameAndDealer .dealer {
  padding: 1px 5px;
}
.Stage2Pinochle .seatArea .allSmallCards .CardHolder,
.Stage2Pinochle .seatArea .smallCardsGroups .CardHolder {
  overflow: hidden;
}
.Stage2Pinochle .seatArea.top,
.Stage2Pinochle .seatArea.bottom {
  display: grid;
  grid-template-areas: ". ." "cards cards";
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  padding: 5px 5px;
  min-height: 65px;
}
.Stage2Pinochle .seatArea.top .nameAndDealer,
.Stage2Pinochle .seatArea.bottom .nameAndDealer {
  display: flex;
  align-self: top;
  align-items: center;
}
.Stage2Pinochle .seatArea.top .nameAndDealer .dealer,
.Stage2Pinochle .seatArea.bottom .nameAndDealer .dealer {
  margin: 0 0 0 15px;
  padding: 1px 5px;
}
.Stage2Pinochle .seatArea.top .miniCards,
.Stage2Pinochle .seatArea.bottom .miniCards {
  grid-area: cards;
}
.Stage2Pinochle .seatArea.top .BidDisplay,
.Stage2Pinochle .seatArea.bottom .BidDisplay {
  justify-content: flex-end;
}
.Stage2Pinochle .seatArea.top .BidDisplay .title span + span,
.Stage2Pinochle .seatArea.bottom .BidDisplay .title span + span {
  margin-left: 1em;
}
.Stage2Pinochle .seatArea.top .dealer {
  align-self: flex-end;
}
.Stage2Pinochle .seatArea.bottom .dealer {
  align-self: flex-start;
}
.Stage2Pinochle .seatArea.left .BidDisplay,
.Stage2Pinochle .seatArea.right .BidDisplay {
  margin: 3px auto;
}
.Stage2Pinochle .seatArea.left .BidDisplay .title span,
.Stage2Pinochle .seatArea.right .BidDisplay .title span {
  display: block;
}
.Stage2Pinochle .seatArea.viewAllCards.top,
.Stage2Pinochle .seatArea.viewAllCards.bottom {
  display: grid;
  grid-template-areas: ". ." "cards cards";
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  max-height: 106px;
  overflow: hidden;
  align-content: flex-start;
  align-items: center;
}
.Stage2Pinochle .seatArea.viewAllCards.top .allSmallCards,
.Stage2Pinochle .seatArea.viewAllCards.bottom .allSmallCards {
  grid-area: cards;
  display: flex;
  padding-top: 5px;
  max-width: 100%;
}
.Stage2Pinochle .seatArea.viewAllCards.top .smallCardsGroups,
.Stage2Pinochle .seatArea.viewAllCards.bottom .smallCardsGroups {
  display: none;
}
.Stage2Pinochle .seatArea.viewAllCards.top .BidDisplay,
.Stage2Pinochle .seatArea.viewAllCards.bottom .BidDisplay {
  justify-content: flex-end;
}
.Stage2Pinochle .seatArea.viewAllCards.left,
.Stage2Pinochle .seatArea.viewAllCards.right {
  justify-content: space-between;
  max-height: 100%;
}
.Stage2Pinochle .seatArea.viewAllCards.left .allSmallCards,
.Stage2Pinochle .seatArea.viewAllCards.right .allSmallCards {
  display: none;
}
.Stage2Pinochle .seatArea.viewAllCards.left .smallCardsGroups,
.Stage2Pinochle .seatArea.viewAllCards.right .smallCardsGroups {
  display: flex;
  overflow: auto;
  align-items: flex-start;
  justify-content: center;
  padding: 2px 0 2px 0;
  margin: 0 -3px 0 -5px;
}
.Stage2Pinochle .seatArea.viewAllCards.left .smallCardsGroups > div,
.Stage2Pinochle .seatArea.viewAllCards.right .smallCardsGroups > div {
  padding: 0 2px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
}
.Stage2Pinochle .cardArea {
  display: flex;
  justify-content: center;
}
.Stage2Pinochle .cardArea .CardHolder {
  display: block;
  margin-right: var(--cardMargin);
}
.Stage2Pinochle .cardArea .CardHolder:last-child {
  margin-right: 5px;
}
.Stage2Pinochle .bidNum {
  font-size: 1.25rem;
}
.Stage2Pinochle .bidNum span {
  display: inline-block;
  text-align: center;
}
.Stage2Pinochle .seatL1 {
  grid-area: seatL1;
}
.Stage2Pinochle .seatL2 {
  grid-area: seatL2;
}
.Stage2Pinochle .seatT1 {
  grid-area: seatT1;
}
.Stage2Pinochle .seatR1 {
  grid-area: seatR1;
}
.Stage2Pinochle .seatR2 {
  grid-area: seatR2;
}
.Stage2Pinochle .bottomDealerDisplay {
  display: none;
  grid-area: dealer;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.5em;
  position: relative;
}
.Stage2Pinochle .bottomDealerDisplay::before {
  content: "Dealer";
  position: absolute;
  top: 2px;
  left: 2px;
}
.Stage2Pinochle.isDealer .bottomDealerDisplay {
  display: block;
}
.Stage2Pinochle .admin {
  display: none;
  grid-area: admin;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.5em;
  position: relative;
}
.Stage2Pinochle .admin::before {
  content: "Admin";
  position: absolute;
  top: 2px;
  left: 2px;
}
.Stage2Pinochle.isAdmin .admin {
  display: block;
}
.Stage2Pinochle .title {
  grid-area: title;
}
.Stage2Pinochle .topLeft {
  grid-area: topLeft;
  font-size: 1.1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Stage2Pinochle .topRight {
  grid-area: topRight;
  font-size: 1.1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Stage2Pinochle .miniCards {
  display: flex;
}
.Stage2Pinochle .miniCards div {
  height: 30px;
  width: 20px;
  background-color: green;
  overflow: hidden;
  margin: 1px 1px 1px 0;
}
.Stage2Pinochle .trump {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.Stage2Pinochle .startTrick {
  margin-right: 2em;
}
.Stage2Pinochle .myButtons {
  position: relative;
  min-height: 30px;
  padding: 0 10px;
  display: grid;
  grid-template-columns: 2fr auto 2fr;
  align-items: center;
}
.Stage2Pinochle .myButtons .myInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Stage2Pinochle .myButtons .myCenterButtons {
  display: flex;
  justify-content: space-evenly;
  white-space: nowrap;
  align-items: center;
}
.Stage2Pinochle .myButtons .myCenterButtons .BidDisplay {
  display: inline-block;
  margin: 0 40px 0 0;
}
.Stage2Pinochle .myButtons .myCenterButtons .el-input-number {
  margin-right: 0.5em;
}
.Stage2Pinochle .CardHolder.notPlayable img {
  filter: brightness(75%);
  animation: all 0.1s;
}
.Stage2Pinochle .CardHolder.playable {
  cursor: pointer;
}
.Stage2Pinochle .CardHolder.playable img {
  filter: brightness(100%);
}
.Stage2Pinochle .CardHolder.tookTrick img {
  animation: pulseWinner 0.3s 9;
  animation-direction: alternate;
}
.Stage2Pinochle .red {
  color: #de2626;
}
.Stage2Pinochle .black {
  color: black;
}
.Stage2Pinochle .dealer {
  font-weight: bold;
  display: block;
  margin: 3px 0;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.2);
}
.Stage2Pinochle.alertMyTurn .table {
  animation: alertTable 0.2s 7;
  animation-direction: alternate;
}
.Stage2Pinochle .YourTurn {
  font-size: 1.1em;
}
.Stage2Pinochle .el-checkbox.is-bordered.el-checkbox--small {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  padding: 1px 10px 1px 0;
  margin: 0 5px 0 10px;
  border: none;
  background-color: #4a993e;
  color: white;
}
.Stage2Pinochle .el-checkbox.is-bordered.el-checkbox--small .el-checkbox__input {
  margin: 2px 0 0 8px;
}
.Stage2Pinochle .el-checkbox.is-bordered.el-checkbox--small .el-checkbox__label {
  color: white;
}
.Stage2Pinochle .el-checkbox.is-bordered.el-checkbox--small .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #006400;
  border-color: #006400;
}
.Stage2Pinochle .chimeSet {
  display: flex;
  position: relative;
  align-items: center;
  background: #4a983e;
  color: white;
  border-radius: 5px;
  margin: 0 0 0 1em;
  padding: 0 5px;
}
.Stage2Pinochle .chimeSet .el-checkbox.is-bordered.el-checkbox--small {
  margin: 0;
}
.Stage2Pinochle .chimeSet select {
  background: #4a983e;
  color: white;
  margin: 0 2px 0 0;
}
.Stage2Pinochle .chimeSet .chimeVolumeHolder {
  margin: 0 1px 0 6px;
  position: relative;
}
.Stage2Pinochle .chimeSet .chimeVolumeHolder:hover .chimeVolume,
.Stage2Pinochle .chimeSet .chimeVolumeHolder:active .chimeVolume {
  display: block;
}
.Stage2Pinochle .chimeSet .chimeVolume {
  position: absolute;
  display: none;
  right: -10px;
  bottom: -5px;
  background: white;
  padding: 15px 0;
  box-shadow: 0 0 3px 1px black;
}
.Stage2Pinochle .totalBids span {
  white-space: nowrap;
}
.Stage2Pinochle .meldDisplay {
  position: absolute;
  z-index: 1;
  background: white;
  border: 1px solid darkgreen;
  box-shadow: 0 0 3px 0 darkgreen;
  padding: 3px 10px 3px 4px;
  overflow: visible;
}
.Stage2Pinochle .meldDisplay > div {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.Stage2Pinochle .meldDisplay > div div {
  white-space: nowrap;
  padding: 2px 7px;
}
.Stage2Pinochle .meldDisplay > div div:last-child {
  padding-left: 15px;
}
@keyframes pulseWinner {
  0% {
    box-shadow: 0px 0px 0 0 #e6a23c;
  }
  100% {
    box-shadow: 0px 0px 6px 3px #e6a23c;
  }
}
@keyframes alertTable {
  0% {
    background-color: #e8f9e6;
  }
  100% {
    background-color: #006400;
  }
}
:root {
  --cardMargin: 0px;
}
