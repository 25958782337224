


















































































































.Admin {
  .forCopy {
    width: 40em;
    input {
      text-align: center;
      font-size: 1.3em;
    }
  }
  .panel {
    &.left {
      text-align: left;
    }
  }
  .el-button {
    margin: 3px 0 0 0;
  }
}
