
























































































































































































































































































































































































































.SetupNames {
  .gameActive {
    color: red;
    font-weight: bold;
    font-size: 1.1em;
  }
  .list-move {
    transition: transform 1s;
  }
  p.warning {
    color: red;
  }

  .list {
    margin: 20px 0;

    .isDup {
      display: inline-block;
      width: 100px;
      color: red;
    }
    input[type="text"] {
      width: 5em;
      margin: 0 20px 0 10px;
      position: relative;

      &.missing {
        box-shadow: 0 0 3px 1px red;
      }

      &.teamName {
        width: 2em;
      }
    }
    .num {
      display: inline-block;
      width: 20px;
      text-align: right;
      margin: 0 5px 0 5px;
      font-size: 75%;
      color: grey;
    }
    label {
      display: inline-block;
      margin: 0 5px;
    }
  }

  [data-title] {
    position: relative;
    &::before {
      content: attr(data-title);
      // More Style Rules
      position: absolute;
      top: -14px;
      left: 10px;
      font-size: 60%;
      color: grey;
    }
  }

  .guest {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px 15px;
    padding: 5px 0;

    .part2 {
      display: flex;
    }
    input {
      margin: 0;

      &.missing {
        box-shadow: 0 0 3px 1px red;
      }
    }
  }

  .forgetBtn {
    width: 90px;
  }

  button.moveMe {
    cursor: ns-resize;
  }
  button.moveMe,
  button.remove {
    margin: 0;
    padding: 0;
    display: inline-block;
    box-shadow: none;
    i {
      vertical-align: middle;
    }
    &:hover {
      background-color: #fcfcfc;
    }
  }
}

// at a global level
.itemHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 15px;
  padding: 5px 0;
  box-sizing: border-box; // match what SlickList uses

  &.active + &.inactive {
    border-top: 3px double red;
    padding-top: 30px;
    position: relative;

    &::before {
      content: "Sitting out this round";
      position: absolute;
      top: -1px;
      left: 0;
      font-size: 75%;
      font-style: italic;
      color: red;
    }
  }

  > div {
    white-space: nowrap;
    margin: 2px;
    display: flex;
    align-items: center;
  }
  .nameWhileMoving {
    display: none;
  }
  &.moving {
    text-align: center;
    background-color: lightblue;
    margin: 5px auto;
    // input {
    //   background-color: lightblue;
    // }
    > * {
      display: none;

      &.nameWhileMoving {
        display: block;
      }
    }
  }
}
