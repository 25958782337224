.KeyTips .el-dialog--center .el-dialog__body {
  padding: 0 25px 30px;
}
.KeyTips .key {
  padding: 0 5px 3px;
  background-color: #1f1f1f;
  color: #faf67a;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  min-width: 9px;
}
.KeyTips .key + .key {
  margin-left: 1px;
}
.KeyTips td,
.KeyTips th {
  vertical-align: top;
}
.KeyTips td.left {
  text-align: right;
  white-space: nowrap;
}
.KeyTips thead th {
  text-align: left;
  padding: 1em 0 0 0;
  border-bottom: 1px solid black;
}
