#nav {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  background: #000;
  color: #fff;
  align-items: center;
  min-height: 2.5em;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
#nav > div {
  flex: 1 1 auto;
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#nav .logoSymbolUrl {
  height: 20px;
  margin-left: 12px;
  margin-right: 12px;
}
#nav .logoSymbolUrl.logo-c,
#nav .logoSymbolUrl.logo-s {
  filter: invert(1);
}
#nav div.middle {
  flex-grow: 19;
  white-space: normal;
  padding: 2px 0 6px;
}
#nav div.middle span {
  margin: 0 15px;
}
#nav .image {
  text-align: left;
  justify-content: left;
  align-items: center;
}
#nav a {
  color: #999;
  font-weight: bold;
  text-decoration: none;
}
#nav a:hover {
  color: #ddd;
}
#nav a.router-link-exact-active {
  color: #fff;
}
#nav a.router-link-exact-active:hover {
  cursor: default;
  color: #fff;
}
#nav a .logoSymbol {
  color: #fff;
}
#nav span {
  display: inline-block;
  margin: 3px 0.5em;
  vertical-align: middle;
}
#nav button {
  font-size: 0.9em;
}
#nav .myName {
  justify-content: flex-end;
  padding-right: 3px;
  align-items: center;
}
#nav .myName .name {
  margin-left: 2em;
}
