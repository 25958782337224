






































































































































































































































































































.MemberStatus {
  flex-shrink: 0;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 2em;
    border-top: 1px solid #5d6560;
    border-bottom: 1px solid #5d6560;
    background-color: #d2d2d2;
    padding: 3px 5px;

    .buttonsL {
      text-align: left;
      .dealerBtns {
        display: flex;
        align-items: center;
      }
    }

    .buttonsR {
      white-space: nowrap;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .people {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
    }

    .guest {
      display: block;
      margin: 0 3px;
      font-style: italic;
      padding: 0px 5px 0px 4px;
      font-size: 90%;
      border-radius: 2px;
      user-select: all;
      position: relative;
      align-self: center;

      background-color: #e1dfc2;

      &.connected {
        background-color: #f0eab1;
      }

      &.connected.highlight {
        animation: pulseGuest 0.3s infinite;
      }

      &.isMe {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
        cursor: pointer;
        user-select: none;
      }
    }

    .activeMember,
    .inactiveMember {
      display: block;
      margin: 3px 10px;
      padding: 1px 3px;
      border-radius: 2px;
      user-select: all;
      position: relative;
      border: 2px solid transparent;

      &.connected.highlight {
        // include more selectors to take priority
        animation: pulse 0.3s infinite;
        //user-select: unset;
      }

      &.isMe {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
        cursor: pointer;
        user-select: none;
      }
    }

    .inactiveMember {
      //background-color: #e9e9e9;
      background-color: rgba(100, 100, 100, 0.1);

      &.connected {
        //background-color: #e8fae8;
        background-color: #cbe6b9;
      }
    }

    .activeMember {
      background-color: rgba(100, 100, 100, 0.1);
      border-color: #aaa;

      &.participating {
        background-color: #efc3c3; // participating, but not connected!
      }

      &.connected {
        background-color: #9fef93;
      }

      &.isNext {
        border-color: #de2626;
      }
    }

    .activeMember + .guest {
      margin-left: 30px;
    }

    .isAdmin:after {
      content: "A"; // for Admin
      position: absolute;
      bottom: -5px;
      right: -8px;
      font-size: 60%;
    }

    .isDealer:after {
      content: "D"; // for Admin
      position: absolute;
      bottom: -5px;
      right: -8px;
      font-size: 60%;
    }
    .isAdmin.isDealer:after {
      content: "D A"; // for Admin
      position: absolute;
      bottom: -5px;
      right: -8px;
      font-size: 60%;
    }

    @keyframes pulse {
      0% {
        background-color: #fff;
      }
      50% {
        background-color: #4a993e;
      }
    }
    @keyframes pulseGuest {
      0% {
        background-color: #fff;
      }
      50% {
        background-color: #f0eab1;
      }
    }
  }
}
