.CardHolder.Standard1 {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 98px;
  height: 147px;
  box-sizing: content-box;
  border-radius: 3px;
  user-select: none;
  transition: 0.1s;
  transform-style: preserve-3d;
}
.CardHolder.Standard1.focused {
  margin: -10px 3px 0;
  box-shadow: 0px 0px 3px 0px black;
  flex-shrink: 0;
}
.CardHolder.Standard1 .Card,
.CardHolder.Standard1 .Back {
  display: block;
  position: absolute;
  font-size: 160px;
  height: 190px;
  top: -43px;
  width: 106px;
  left: -4px;
  background-color: white;
  backface-visibility: hidden;
}
.CardHolder.Standard1 .Card.red,
.CardHolder.Standard1 .Back.red {
  color: #de2626;
}
.CardHolder.Standard1 .Card.black,
.CardHolder.Standard1 .Back.black {
  color: black;
}
.CardHolder.Standard1 .Card {
  z-index: 2;
  transform: rotateY(0deg);
}
.CardHolder.Standard1 .Back {
  transform: rotateY(180deg);
}
