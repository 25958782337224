.Stage3 {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.Stage3 .grid-container {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 0.7fr 2.5fr 0.7fr;
  grid-template-rows: 0.2fr 0.6fr 4fr 240px;
  gap: 3px 3px;
  grid-template-areas: "title title title" "topLeft p2 topRight" "p1 table p3" "myArea myArea myArea";
}
.Stage3 .grid-container button {
  align-self: center;
}
.Stage3 .table {
  grid-area: table;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #e8f9e6;
  background-clip: padding-box;
  border: solid 5px transparent;
  border-radius: 1em;
  position: relative;
}
.Stage3 .table .tableCenter {
  grid-area: tableCenter;
  height: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.Stage3 .table .playArea {
  flex-grow: 1;
  margin: 0 auto;
}
.Stage3 .table .playArea .cardArea {
  position: relative;
}
.Stage3 .table .playArea .cardArea .CardHolder {
  position: absolute;
}
.Stage3 .table .playArea .cardArea .CardHolder.offset0 {
  left: -50px;
}
.Stage3 .table .playArea .cardArea .CardHolder.offset1 {
  left: -25px;
}
.Stage3 .table .playArea .cardArea .CardHolder.offset2 {
  left: 0;
}
.Stage3 .table:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -5px;
  border-radius: inherit;
  background: -webkit-gradient(linear, left top, right top, from(red), to(orange));
  background: linear-gradient(to right, red, orange);
}
.Stage3 .myArea {
  grid-area: myArea;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.Stage3 .myArea .cardArea {
  margin-top: 20px;
  padding: 0 1em;
}
.Stage3 .myArea .cardArea .CardHolder {
  margin: 0 1px;
}
.Stage3 .cardArea {
  display: flex;
  justify-content: center;
}
.Stage3 .p1 {
  grid-area: p1;
  background-color: lightskyblue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.Stage3 .p2 {
  grid-area: p2;
  background-color: lightsteelblue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.Stage3 .p3 {
  grid-area: p3;
  background-color: lightblue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.Stage3 .dealer {
  display: none;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.5em;
  position: relative;
}
.Stage3 .dealer::before {
  content: "Dealer";
  position: absolute;
  top: 2px;
  left: 2px;
}
.Stage3.isDealer .dealer {
  display: block;
}
.Stage3 .admin {
  display: none;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.5em;
  position: relative;
}
.Stage3 .admin::before {
  content: "Admin";
  position: absolute;
  top: 2px;
  left: 2px;
}
.Stage3.isAdmin .admin {
  display: block;
}
.Stage3 .title {
  grid-area: title;
}
.Stage3 .topLeft {
  grid-area: topLeft;
}
.Stage3 .topRight {
  grid-area: topRight;
}
.Stage3 .miniCards {
  margin: 0 1em;
  font-size: 130%;
}
.Stage3 .miniCards span {
  margin: 0 1px;
}
.Stage3 .myButtons {
  position: relative;
  height: 30px;
}
.Stage3 .myButtons button {
  margin: 0 2em;
}
.Stage3 .CardHolder.focused {
  margin: -10px 3px 0;
  box-shadow: 0px 0px 3px 0px black;
  flex-shrink: 0;
}
.Stage3 .trumpInfo {
  transition: 0.2s;
}
.Stage3 .trumpSymbol {
  font-size: 150%;
}
