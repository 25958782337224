.public {
  padding: 1em;
  font-family: "Open Sans", sans-serif;
}
.public h1,
.public h2 {
  font-family: "Acme", sans-serif;
  color: #1e331d;
}
.public img.main {
  display: block;
  margin: 0 auto;
  height: 40vh;
  min-height: 300px;
  max-height: 700px;
}
.public button {
  font-size: 1.25em;
}
