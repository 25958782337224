











































































































































.ClaimName {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 10px;

  table {
    margin: 1em auto;
    border-collapse: collapse;
    th {
      font-weight: normal;
      padding-right: 40px;
    }
  }

  td,
  th {
    padding: 0 1em;
  }

  tfoot {
    background-color: #e9e9e9;
    tr:first-child {
      border-top: 1px solid grey;
    }
  }

  tr.memberHolder {
    height: 3em;
    cursor: pointer;

    &.nextPlayer {
      th {
        font-weight: bold;
      }
    }
  }
  .loading {
    margin: 20px;
  }
}
