
























































































.BidDisplay {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;

  .title {
    padding: 0 1em;
    white-space: normal;
    span {
      white-space: nowrap;
    }
  }

  i {
    font-size: 1.4rem;
    margin-top: 3px;
    font-weight: bold;

    &.el-icon-remove-outline {
      color: #e0e0e0;
    }

    &.el-icon-circle-check {
      color: #4a993e;
    }

    &.el-icon-circle-plus-outline {
      color: #d40600;
    }
  }

  .roundFinished {
    i.el-icon-remove-outline {
      color: #ed9b9f;
    }
  }

  .el-steps {
    flex-wrap: wrap;
    justify-content: center;
  }

  .el-step {
    margin: 0 0 3px 0;
    min-width: 24px;
  }

  .el-step__title {
    line-height: normal;
  }

  .el-step__line {
    display: none; // don't need it
  }
}
