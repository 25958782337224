




































































































































































#nav {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  // display: flex;
  // flex-shrink: 0;
  // justify-content: space-between;
  background: #000;
  color: #fff;
  align-items: center;
  min-height: 2.5em;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  > div {
    flex: 1 1 auto;
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  // .logoSymbol {
  //   font-size: 1.7em;
  //   margin: -13px 0.5em 0;
  // }
  .logoSymbolUrl {
    height: 20px;
    margin-left: 12px;
    margin-right: 12px;

    &.logo-c,
    &.logo-s {
      filter: invert(1);
    }
  }

  div.middle {
    flex-grow: 19;
    white-space: normal;
    padding: 2px 0 6px;
    span {
      margin: 0 15px;
    }
  }

  .image {
    text-align: left;
    justify-content: left;
    align-items: center;
  }

  a {
    color: #999;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: #ddd;
    }

    &.router-link-exact-active {
      color: #fff;
      &:hover {
        cursor: default;
        color: #fff;
      }
    }

    .logoSymbol {
      color: #fff;
    }
  }

  span {
    display: inline-block;
    margin: 3px 0.5em;
    vertical-align: middle;
  }

  button {
    font-size: 0.9em;
  }

  .myName {
    justify-content: flex-end;
    padding-right: 3px;
    align-items: center;
    .name {
      margin-left: 2em;
    }
  }
}
