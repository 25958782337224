.ScoreSheet .dialogFooter {
  text-align: right;
}
.ScoreSheet .NoScores {
  font-size: 1.1em;
  margin: 2em auto;
  text-align: center;
}
.ScoreSheet table {
  border-collapse: collapse;
  margin: 0 auto;
}
.ScoreSheet td,
.ScoreSheet th {
  padding: 5px 3px;
  text-align: center;
  white-space: nowrap;
}
.ScoreSheet th.bottom {
  vertical-align: bottom;
}
.ScoreSheet th.suit {
  padding: 3px 0 0 3px;
}
.ScoreSheet th.suit img {
  height: 18px;
}
.ScoreSheet .bid {
  border-left: 1px solid black;
}
.ScoreSheet .rank {
  border-right: 1px solid black;
}
.ScoreSheet .rank1 {
  background-color: #c1fcc1;
}
.ScoreSheet .bid.ok,
.ScoreSheet .got.ok {
  background-color: #e1ffe1;
}
.ScoreSheet .bid.viewer,
.ScoreSheet .got.viewer {
  background-color: #fff;
}
.ScoreSheet .bid.missed,
.ScoreSheet .got.missed {
  background-color: #ffebeb;
}
.ScoreSheet .red {
  color: #de2626;
}
.ScoreSheet .black {
  color: black;
}
.ScoreSheet .score {
  border-left: 1px solid #aaa;
  border-right: 1px solid black;
}
.ScoreSheet .score.played {
  background-color: #f9f9f9;
}
.ScoreSheet .score.topScore {
  background-color: #c1fcc1;
}
.ScoreSheet .name {
  border: 1px solid black;
}
.ScoreSheet tbody tr {
  border-top: 1px solid grey;
}
.ScoreSheet tfoot tr:first-child {
  border-top: 3px double #000;
}
.ScoreSheet tfoot td,
.ScoreSheet tfoot th {
  padding: 2px 3px;
}
.ScoreSheet tfoot .rankLeftSide {
  text-align: right;
}
.ScoreSheet .rules {
  margin: 2em auto 0;
  width: 39em;
  text-align: left;
}
