















































































































.CardHolder.Standard1 {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 98px;
  height: 147px;
  box-sizing: content-box;
  border-radius: 3px;
  user-select: none;

  transition: 0.1s;
  transform-style: preserve-3d;

  &.focused {
    margin: -10px 3px 0;
    box-shadow: 0px 0px 3px 0px black;
    flex-shrink: 0;
  }

  // &:hover {
  //   transform: rotateY(180deg;);
  // }
  .Card,
  .Back {
    display: block;
    position: absolute;
    font-size: 160px;
    height: 190px;
    top: -43px;
    width: 106px;
    left: -4px;
    background-color: white;

    backface-visibility: hidden;

    &.red {
      color: #de2626;
      // background: #fff2f2;
    }
    &.black {
      color: black;
    }
  }

  .Card {
    z-index: 2;
    transform: rotateY(0deg);
  }
  .Back {
    transform: rotateY(180deg);
  }
}
