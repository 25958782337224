.ClaimName {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 10px;
}
.ClaimName table {
  margin: 1em auto;
  border-collapse: collapse;
}
.ClaimName table th {
  font-weight: normal;
  padding-right: 40px;
}
.ClaimName td,
.ClaimName th {
  padding: 0 1em;
}
.ClaimName tfoot {
  background-color: #e9e9e9;
}
.ClaimName tfoot tr:first-child {
  border-top: 1px solid grey;
}
.ClaimName tr.memberHolder {
  height: 3em;
  cursor: pointer;
}
.ClaimName tr.memberHolder.nextPlayer th {
  font-weight: bold;
}
.ClaimName .loading {
  margin: 20px;
}
