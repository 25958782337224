.CreateGame {
  text-align: left;
  padding: 0 10px;
}
.CreateGame .nameInput {
  margin: 0 0 1em;
  display: flex;
  align-items: center;
}
.CreateGame .nameInput input {
  width: 60px;
  margin: 0 2em 0 1em;
}
.CreateGame .nameInput .googleBtn {
  height: 46px;
  margin-left: 1em;
}
.CreateGame .loading {
  margin: 50px auto;
  text-align: center;
  font-size: 1.1em;
}
.CreateGame .leave {
  margin-top: 50px;
}
.CreateGame .gamesList {
  max-height: 50vh;
  overflow: auto;
}
.CreateGame .previousGames td {
  padding: 3px 5px 0 0;
  vertical-align: top;
}
.CreateGame .previousGames .name {
  font-weight: bold;
}
.CreateGame .previousGames .age {
  color: #666;
  font-size: 85%;
  white-space: nowrap;
}
