.MemberStatus {
  flex-shrink: 0;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.MemberStatus .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 2em;
  border-top: 1px solid #5d6560;
  border-bottom: 1px solid #5d6560;
  background-color: #d2d2d2;
  padding: 3px 5px;
}
.MemberStatus .top .buttonsL {
  text-align: left;
}
.MemberStatus .top .buttonsL .dealerBtns {
  display: flex;
  align-items: center;
}
.MemberStatus .top .buttonsR {
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.MemberStatus .top .people {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}
.MemberStatus .top .guest {
  display: block;
  margin: 0 3px;
  font-style: italic;
  padding: 0px 5px 0px 4px;
  font-size: 90%;
  border-radius: 2px;
  user-select: all;
  position: relative;
  align-self: center;
  background-color: #e1dfc2;
}
.MemberStatus .top .guest.connected {
  background-color: #f0eab1;
}
.MemberStatus .top .guest.connected.highlight {
  animation: pulseGuest 0.3s infinite;
}
.MemberStatus .top .guest.isMe {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  user-select: none;
}
.MemberStatus .top .activeMember,
.MemberStatus .top .inactiveMember {
  display: block;
  margin: 3px 10px;
  padding: 1px 3px;
  border-radius: 2px;
  user-select: all;
  position: relative;
  border: 2px solid transparent;
}
.MemberStatus .top .activeMember.connected.highlight,
.MemberStatus .top .inactiveMember.connected.highlight {
  animation: pulse 0.3s infinite;
}
.MemberStatus .top .activeMember.isMe,
.MemberStatus .top .inactiveMember.isMe {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  user-select: none;
}
.MemberStatus .top .inactiveMember {
  background-color: rgba(100, 100, 100, 0.1);
}
.MemberStatus .top .inactiveMember.connected {
  background-color: #cbe6b9;
}
.MemberStatus .top .activeMember {
  background-color: rgba(100, 100, 100, 0.1);
  border-color: #aaa;
}
.MemberStatus .top .activeMember.participating {
  background-color: #efc3c3;
}
.MemberStatus .top .activeMember.connected {
  background-color: #9fef93;
}
.MemberStatus .top .activeMember.isNext {
  border-color: #de2626;
}
.MemberStatus .top .activeMember + .guest {
  margin-left: 30px;
}
.MemberStatus .top .isAdmin:after {
  content: "A";
  position: absolute;
  bottom: -5px;
  right: -8px;
  font-size: 60%;
}
.MemberStatus .top .isDealer:after {
  content: "D";
  position: absolute;
  bottom: -5px;
  right: -8px;
  font-size: 60%;
}
.MemberStatus .top .isAdmin.isDealer:after {
  content: "D A";
  position: absolute;
  bottom: -5px;
  right: -8px;
  font-size: 60%;
}
@keyframes pulse {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #4a993e;
  }
}
@keyframes pulseGuest {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #f0eab1;
  }
}
