





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.Stage2Pinochle {
  //display: flex;
  //flex-direction: column;
  min-height: 100%;
  height: 100%;
  position: relative;

  &.next {
    .myArea {
      border-color: #de2626;
    }
  }

  .drawPile {
    position: relative;
    padding: 0.5em;
    width: 98px;
    height: 147px;

    border: 1px solid black;

    .CardHolder {
      position: absolute;
      top: 0.5em;
      left: 0.5em;
    }
  }

  @myArea: 240px;
  @myAreaViewer: auto;

  .seatingWithTable {
    flex-grow: 1;
    display: grid;
    // max-height: 100%;
    min-height: 400px;
    // height: 100%;
    gap: 3px 3px;
    grid-template-columns: minmax(0, 0.7fr) 2fr minmax(0, 0.7fr);
    grid-template-rows: auto minmax(330px, auto) @myArea;
    grid-template-areas:
      "topLeft seatT1 topRight"
      "seatL1 table seatR1"
      "myArea myArea myArea";

    button {
      align-self: center;
    }
  }

  .table {
    grid-area: table;
    min-height: 320px;

    display: grid;
    gap: 1px 1px;
    grid-template-columns: 1fr 115px 1fr;
    grid-template-rows: 1.4fr auto 1.5fr auto; // old edge - don't use fr for middle
    grid-template-areas:
      "tableL1 tableT1 tableR1"
      "tableL1 tableCenter tableR1"
      "tableL1 myPlay tableR1"
      "trumpSuit tableButtons leadSuit";

    position: relative;

    background: #e8f9e6;
    background-clip: padding-box;
    border: solid 5px transparent;
    border-radius: 25px;

    // border-radius: 1em;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -5px;
      border-radius: inherit;
      // background: linear-gradient(to right, red, orange);
      background: darkgreen;
    }

    &.isViewer {
      .seatingWithTable {
        grid-template-rows: auto minmax(320px, 1.3fr) @myAreaViewer;
      }
    }
    .mainLogo {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 40%;
      transition: all 0.5s;
      img {
        height: 140%;
      }
    }
    .cardArea {
      display: flex;
      // justify-content: center;
      align-items: center;
      position: relative;

      .CardHolder {
        display: block;
        margin-right: -96px;

        &:last-child {
          margin-right: 5px;
        }
      }

      &.left {
        .meldDisplay {
          right: 30%;
        }
      }

      &.right {
        .meldDisplay {
          left: 30%;
        }
      }

      &.top {
        .meldDisplay {
          top: 37%;
        }
      }

      &.bottom {
        .meldDisplay {
          bottom: 0;
        }
      }
    }
  }

  &.players_4 {
    .seatingWithTable {
      grid-template-areas:
        "topLeft seatT1 topRight"
        "seatL1 table seatR1"
        "myArea myArea myArea";
    }
    .table {
      grid-template-rows: 1.4fr auto 1.5fr auto; // old edge - don't use fr for middle
      grid-template-areas:
        "tableL1 tableT1 tableR1"
        "tableL1 tableCenter tableR1"
        "tableL1 myPlay tableR1"
        "trumpSuit tableButtons leadSuit";
    }
    &.isViewer {
      .seatingWithTable {
        grid-template-rows: auto minmax(200px, 1fr) @myAreaViewer;
      }
      .table {
        grid-template-areas:
          "tableL1 tableT1 tableR1"
          "tableL1 tableCenter tableR1"
          "tableL1 tableB1 tableR1"
          "trumpSuit tableButtons leadSuit";
      }
    }
  }

  .p0table,
  .seatB1 {
    display: none;
  }

  .tableButtons {
    grid-area: tableButtons;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 5px;
    z-index: 2;
    button {
      white-space: nowrap;
    }
  }
  .trumpSuit {
    grid-area: trumpSuit;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 3px;
    .trumpInfo {
      margin: -1rem 0 -0.5rem 10px;
      font-size: 2.1rem;
    }
  }
  .leadSuit {
    grid-area: leadSuit;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 3px;
    .trumpInfo {
      margin: -1rem 10px -0.5rem 0;
      font-size: 2.1rem;
    }
  }

  &.isViewer {
    .cardArea.p0table {
      grid-area: tableB1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;

      .meldDisplay {
        bottom: 3px;
        left: 50%;
        transform: translate(-50%);
      }
    }

    .smallCardsGroups {
      .CardHolder {
        max-height: 40px; // flex not working
      }
    }

    // .p0table {
    //   display: flex;
    //   grid-area: p0table;
    // }
    // .p0left {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   grid-area: p0left;
    // }
    // .p0right {
    //   display: flex;
    //   grid-area: p0right;
    // }

    .seatB1 {
      grid-area: myArea;
    }

    .myPlay {
      display: none;
    }
    .myArea {
      display: none;
    }
  }

  .tableCenter {
    // grid-area: tableCenter;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-self: center;
    font-size: 1.7em;
    overflow: visible;
    position: absolute;
    z-index: 1;
    left: 25%;
    right: 25%;
    // min-height: 2em;
    width: auto;
    text-align: center;
    padding: 0.25em 0.5em 0.25em;
    overflow: visible;
    background: white;
    border: 2px solid grey;
    border-radius: 5px;
    box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 1);

    button {
      margin-top: 1em;
    }
  }

  .myPlay {
    grid-area: myPlay;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;

    .meldDisplay {
      bottom: 3px;
      left: 50%;
      transform: translate(-50%);
    }
  }

  .tableT1 {
    grid-area: tableT1;
    display: flex;
    justify-content: stretch;
    align-content: center;
    padding-top: 6px;
    .bidNum {
      // justify-self: flex-start;
      height: 100%;
    }
  }

  .tableL1 {
    grid-area: tableL1;
    .bidNum {
      padding-left: 8%;
      text-align: left;
      width: 100%;
    }
  }

  .tableR1 {
    grid-area: tableR1;
    .bidNum {
      padding-right: 10%;
      text-align: right;
      width: 100%;
    }
  }

  .tableL1,
  .tableL2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .tableR1,
  .tableR2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .myArea {
    grid-area: myArea;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 0 2px;
    border: lightblue 6px solid;
    background: #fbfbf9;
    border-radius: 5px;

    .cardArea {
      margin-top: 20px;
      padding: 0 5px;
      height: 150px;
      align-items: center;
    }

    .suit_c + .suit_s,
    .suit_s + .suit_c,
    .suit_d + .suit_h,
    .suit_h + .suit_d {
      margin-left: 5px;
    }
    .BidDisplay {
      .title {
        span + span {
          margin-left: 1em;
        }
      }
    }
  }

  .seatArea {
    // for other players, or all for viewers
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    align-content: center;
    padding: 5px 5px 0;
    border: lightblue 6px solid;
    background: #fbfbf9;
    border-radius: 5px;

    &.next {
      border-color: #de2626;
    }
    .name {
      text-align: left;
      font-weight: bold;
    }

    .nameAndDealer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-self: top;
      align-items: center;
      .dealer {
        padding: 1px 5px;
      }
    }

    .allSmallCards,
    .smallCardsGroups {
      .CardHolder {
        overflow: hidden;
      }
    }

    &.top,
    &.bottom {
      display: grid;
      grid-template-areas: ". ." "cards cards";
      grid-template-columns: 1fr auto;
      grid-template-rows: auto auto;
      padding: 5px 5px;
      min-height: 65px;

      .nameAndDealer {
        display: flex;
        align-self: top;
        align-items: center;
        .dealer {
          margin: 0 0 0 15px;
          padding: 1px 5px;
        }
      }

      .miniCards {
        grid-area: cards;
      }
      .BidDisplay {
        justify-content: flex-end;
        .title {
          span + span {
            margin-left: 1em;
          }
        }
      }
    }
    &.top {
      .dealer {
        align-self: flex-end;
      }
    }
    &.bottom {
      .dealer {
        align-self: flex-start;
      }
    }

    &.left,
    &.right {
      .BidDisplay {
        margin: 3px auto;
        .title {
          span {
            display: block;
          }
        }
      }
    }

    &.viewAllCards {
      &.top,
      &.bottom {
        display: grid;
        grid-template-areas: ". ." "cards cards";
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto;
        max-height: 106px;
        overflow: hidden;
        align-content: flex-start;
        align-items: center;

        .allSmallCards {
          grid-area: cards;
          display: flex;
          padding-top: 5px;
          max-width: 100%;
        }
        .smallCardsGroups {
          display: none;
        }
        .BidDisplay {
          justify-content: flex-end;
        }
      }

      &.left,
      &.right {
        justify-content: space-between;
        max-height: 100%;

        .allSmallCards {
          display: none;
        }
        .smallCardsGroups {
          display: flex;
          overflow: auto;
          align-items: flex-start;
          justify-content: center;
          padding: 2px 0 2px 0;
          margin: 0 -3px 0 -5px;

          & > div {
            padding: 0 2px;
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: flex-start;
            overflow-x: hidden;
          }
        }
      }
    }
  }

  .cardArea {
    display: flex;
    justify-content: center;

    .CardHolder {
      display: block;
      // margin-right: calc(var(--numCards) * -5px); //TODO
      margin-right: var(--cardMargin);

      &:last-child {
        margin-right: 5px;
      }
    }
  }
  .bidNum {
    font-size: 1.25rem;
    span {
      display: inline-block;
      text-align: center;
    }
  }

  .seatL1 {
    grid-area: seatL1;
  }

  .seatL2 {
    grid-area: seatL2;
  }

  .seatT1 {
    grid-area: seatT1;
  }

  .seatR1 {
    grid-area: seatR1;
  }

  .seatR2 {
    grid-area: seatR2;
  }

  .bottomDealerDisplay {
    display: none;
    grid-area: dealer;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 0.5em;
    position: relative;
    &::before {
      content: "Dealer";
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }

  &.isDealer {
    .bottomDealerDisplay {
      display: block;
    }
  }

  .admin {
    display: none;
    grid-area: admin;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0.5em;
    position: relative;
    &::before {
      content: "Admin";
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }

  &.isAdmin {
    .admin {
      display: block;
    }
  }

  .title {
    grid-area: title;
  }

  .topLeft {
    grid-area: topLeft;
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .topRight {
    grid-area: topRight;
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .miniCards {
    display: flex;
    div {
      height: 30px;
      width: 20px;
      background-color: green;
      overflow: hidden;
      margin: 1px 1px 1px 0;
    }
  }

  .trump {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .startTrick {
    margin-right: 2em;
  }
  .myButtons {
    position: relative;
    min-height: 30px;
    padding: 0 10px;
    display: grid;
    grid-template-columns: 2fr auto 2fr;
    align-items: center;

    // .bidding {
    //   justify-self: start;
    // }

    .myInfo {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .myCenterButtons {
      display: flex;
      justify-content: space-evenly;
      white-space: nowrap;
      align-items: center;
      .BidDisplay {
        display: inline-block;
        margin: 0 40px 0 0;
      }
      .el-input-number {
        margin-right: 0.5em;
      }
    }
  }
  .CardHolder {
    &.notPlayable {
      // opacity: 0.3;
      img {
        filter: brightness(75%);
        animation: all 0.1s;
      }
    }
    &.playable {
      img {
        filter: brightness(100%); // needed!
      }
      cursor: pointer;
    }
    &.tookTrick {
      img {
        animation: pulseWinner 0.3s 9; // use odd times
        animation-direction: alternate;
      }
    }
  }

  // &.trump_d {
  //   .suit_d {
  //     .Card {
  //       background-color: #fff2f2;
  //     }
  //   }
  // }

  // &.trump_c {
  //   .suit_c {
  //     .Card {
  //       background-color: #f3f3ee;
  //     }
  //   }
  // }

  // &.trump_h {
  //   .suit_h {
  //     .Card {
  //       background-color: #fff2f2;
  //     }
  //   }
  // }

  // &.trump_s {
  //   .suit_s {
  //     .Card {
  //       background-color: #f3f3ee;
  //     }
  //   }
  // }

  .red {
    color: #de2626;
    // background: #fff2f2;
  }
  .black {
    color: black;
  }
  .dealer {
    font-weight: bold;
    display: block;
    margin: 3px 0;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &.alertMyTurn {
    .table {
      animation: alertTable 0.2s 7; // use odd times
      animation-direction: alternate;
    }
  }

  .YourTurn {
    font-size: 1.1em;
  }

  .el-checkbox.is-bordered.el-checkbox--small {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    height: 25px;
    padding: 1px 10px 1px 0;
    margin: 0 5px 0 10px;
    border: none;
    background-color: #4a993e;
    color: white;
    .el-checkbox__input {
      margin: 2px 0 0 8px;
    }
    .el-checkbox__label {
      color: white;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #006400;
      border-color: #006400;
    }
  }

  .chimeSet {
    display: flex;
    position: relative;
    align-items: center;
    background: #4a983e;
    color: white;
    border-radius: 5px;
    margin: 0 0 0 1em;
    padding: 0 5px;
    .el-checkbox.is-bordered.el-checkbox--small {
      margin: 0;
    }
    select {
      background: #4a983e;
      color: white;
      margin: 0 2px 0 0;
    }
    .chimeVolumeHolder {
      margin: 0 1px 0 6px;
      position: relative;
      &:hover,
      &:active {
        .chimeVolume {
          display: block;
        }
      }
    }
    .chimeVolume {
      position: absolute;
      display: none;
      right: -10px;
      bottom: -5px;
      background: white;
      padding: 15px 0;
      box-shadow: 0 0 3px 1px black;
    }
  }

  .totalBids {
    span {
      white-space: nowrap;
    }
  }

  // &.bid {
  //   .CardHolder.Standard2.focused {
  //     flex-shrink: 1; // selected, but not playable
  //   }
  // }

  .meldDisplay {
    position: absolute;
    z-index: 1;
    background: white;
    border: 1px solid darkgreen;
    box-shadow: 0 0 3px 0 darkgreen;
    padding: 3px 10px 3px 4px;
    overflow: visible;
    > div {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      div {
        white-space: nowrap;
        padding: 2px 7px;

        &:last-child {
          padding-left: 15px;
        }
      }
    }
  }
}

@keyframes pulseWinner {
  0% {
    box-shadow: 0px 0px 0 0 #e6a23c;
  }

  100% {
    box-shadow: 0px 0px 6px 3px #e6a23c;
  }
}
@keyframes alertTable {
  0% {
    background-color: #e8f9e6;
  }

  100% {
    background-color: #006400;
  }
}
:root {
  --cardMargin: 0px;
}
