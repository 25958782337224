.CardHolder.Standard2 {
  display: inline-block;
  overflow: visible;
  user-select: none;
}
.CardHolder.Standard2 .Card {
  height: calc(95vh * 0.2);
  width: calc(95vh * 0.1429);
  border: 1px solid #666;
  border-radius: 5px;
  box-shadow: 0 0 0 1px #c0c4cc;
}
.CardHolder.Standard2.focused {
  margin-top: -20px;
  transition: all 0.1s;
  flex-shrink: 0;
}
.CardHolder.Standard2.focused .Card {
  box-shadow: 1px 1px 5px 0px #a0a4ac;
}
.CardHolder.Standard2.select {
  flex-shrink: 0;
}
.CardHolder.Standard2.select .Card {
  border: 1px solid black;
}
