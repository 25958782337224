
































































































.CardHolder.Draw1 {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 98px;
  height: 147px;
  box-sizing: content-box;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;

  transition: 0.1s;

  .Card,
  .Back {
    width: 96px;
    height: 145px;
    border: 1px solid black;
    border-radius: 5px;
    position: relative;

    &.red {
      background-color: #de2626;
    }
    &.black {
      background-color: black;
      color: white;
    }
    &.green {
      background-color: lightgreen;
    }
    &.yellow {
      background-color: lightyellow;
    }
    &.blue {
      background-color: lightblue;
    }
  }
  .tl {
    top: -3px;
    left: 3px;
  }
  .br {
    bottom: 0;
    right: 4px;
  }
  .tl,
  .br,
  .center {
    position: absolute;
    font-size: 1.7em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  .center {
    left: 0;
    right: 0;
    top: 40%;
    font-size: 2em;
  }
  .letter {
    .center {
      font-size: 1.5em;
      div {
        transform: rotateZ(-45deg);
      }
    }
  }
}
