.CardHolder.Draw1 {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 98px;
  height: 147px;
  box-sizing: content-box;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  transition: 0.1s;
}
.CardHolder.Draw1 .Card,
.CardHolder.Draw1 .Back {
  width: 96px;
  height: 145px;
  border: 1px solid black;
  border-radius: 5px;
  position: relative;
}
.CardHolder.Draw1 .Card.red,
.CardHolder.Draw1 .Back.red {
  background-color: #de2626;
}
.CardHolder.Draw1 .Card.black,
.CardHolder.Draw1 .Back.black {
  background-color: black;
  color: white;
}
.CardHolder.Draw1 .Card.green,
.CardHolder.Draw1 .Back.green {
  background-color: lightgreen;
}
.CardHolder.Draw1 .Card.yellow,
.CardHolder.Draw1 .Back.yellow {
  background-color: lightyellow;
}
.CardHolder.Draw1 .Card.blue,
.CardHolder.Draw1 .Back.blue {
  background-color: lightblue;
}
.CardHolder.Draw1 .tl {
  top: -3px;
  left: 3px;
}
.CardHolder.Draw1 .br {
  bottom: 0;
  right: 4px;
}
.CardHolder.Draw1 .tl,
.CardHolder.Draw1 .br,
.CardHolder.Draw1 .center {
  position: absolute;
  font-size: 1.7em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.CardHolder.Draw1 .center {
  left: 0;
  right: 0;
  top: 40%;
  font-size: 2em;
}
.CardHolder.Draw1 .letter .center {
  font-size: 1.5em;
}
.CardHolder.Draw1 .letter .center div {
  transform: rotateZ(-45deg);
}
