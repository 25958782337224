.SetupGame .questions {
  margin: 1em 0;
  text-align: left;
  display: flex;
  justify-content: space-around;
}
.SetupGame .questions > div {
  max-width: 60%;
}
.SetupGame .questions > div .el-checkbox__label {
  white-space: normal;
  vertical-align: top;
}
.SetupGame .questions .BooleanSwitch > span {
  margin-right: 0.5em;
}
.SetupGame .summary {
  font-style: italic;
}
.SetupGame .el-checkbox__label {
  font-size: 1rem;
}
