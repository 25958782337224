






































































































































































































.CreateGame {
  text-align: left;
  // padding: 0 0 0 15px;
  // max-width: 700px;
  padding: 0 10px;
  // margin: 0 auto;

  .nameInput {
    margin: 0 0 1em;
    input {
      width: 60px;
      margin: 0 2em 0 1em;
    }
    display: flex;
    align-items: center;
    .googleBtn {
      height: 46px;
      margin-left: 1em;
    }
  }
  .loading {
    margin: 50px auto;
    text-align: center;
    font-size: 1.1em;
  }
  .leave {
    margin-top: 50px;
  }
  .gamesList {
    max-height: 50vh;
    overflow: auto;
  }
  .previousGames {
    td {
      padding: 3px 5px 0 0;
      vertical-align: top;
    }
    .name {
      font-weight: bold;
    }
    .age {
      color: #666;
      font-size: 85%;
      white-space: nowrap;
    }
  }
}
