.gameShell {
  height: 100%;
  text-align: center;
  display: grid;
  grid-template-rows: 40px 99fr 1fr;
  grid-template-areas: "a" "b" "c";
}
.gameShell #nav {
  grid-area: a;
}
.gameShell .MemberStatus {
  grid-area: c;
}
.gameShell #gameBody {
  grid-area: b;
}
.gameShell .gameBody {
  flex-grow: 1;
  overflow-y: auto;
}
