





















































































































































































































.GameRules {
  .el-dialog__body {
    text-align: left;
  }
  .dialogFooter {
    text-align: right;
  }
}
