








































































































































































































































































































.Stage3 {
  height: 100%;
  display: flex;
  flex-direction: column;

  @myArea: 240px;

  .grid-container {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 0.7fr 2.5fr 0.7fr;
    grid-template-rows: 0.2fr 0.6fr 4fr @myArea;
    gap: 3px 3px;

    button {
      align-self: center;
    }

    grid-template-areas:
      "title title title"
      "topLeft p2 topRight"
      "p1 table p3"
      "myArea myArea myArea";
  }

  .table {
    grid-area: table;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: #e8f9e6;
    background-clip: padding-box;
    border: solid 5px transparent;
    border-radius: 1em;

    .tableCenter {
      grid-area: tableCenter;
      height: 2em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }

    .playArea {
      flex-grow: 1;
      margin: 0 auto;

      .cardArea {
        position: relative;
        .CardHolder {
          position: absolute;
          &.offset0 {
            left: -50px;
          }
          &.offset1 {
            left: -25px;
          }
          &.offset2 {
            left: 0;
          }
        }
      }
    }

    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -5px;
      border-radius: inherit;
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(red),
        to(orange)
      );
      background: linear-gradient(to right, red, orange);
    }
  }
  .myArea {
    grid-area: myArea;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    .cardArea {
      margin-top: 20px;
      padding: 0 1em;
      .CardHolder {
        margin: 0 1px;
      }
    }
  }

  .cardArea {
    display: flex;
    justify-content: center;
  }

  .p1 {
    grid-area: p1;
    background-color: lightskyblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .p2 {
    grid-area: p2;
    background-color: lightsteelblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .p3 {
    grid-area: p3;
    background-color: lightblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .dealer {
    display: none;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 0.5em;
    position: relative;
    &::before {
      content: "Dealer";
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }

  &.isDealer {
    .dealer {
      display: block;
    }
  }

  .admin {
    display: none;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0.5em;
    position: relative;
    &::before {
      content: "Admin";
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }

  &.isAdmin {
    .admin {
      display: block;
    }
  }

  .title {
    grid-area: title;
  }

  .topLeft {
    grid-area: topLeft;
  }

  .topRight {
    grid-area: topRight;
  }

  .miniCards {
    margin: 0 1em;
    font-size: 130%;
    span {
      margin: 0 1px;
    }
  }
  .myButtons {
    position: relative;
    height: 30px;
    button {
      margin: 0 2em;
    }
  }
  .CardHolder.focused {
    margin: -10px 3px 0;
    box-shadow: 0px 0px 3px 0px black;
    flex-shrink: 0;
    // .Card {
    // }
  }

  .trumpInfo {
    transition: 0.2s;
  }

  .trumpSymbol {
    font-size: 150%;
  }
}
