





















































































































































































































































































































































































































.ScoreSheet {
  .dialogFooter {
    text-align: right;
  }
  .NoScores {
    font-size: 1.1em;
    margin: 2em auto;
    text-align: center;
  }

  table {
    border-collapse: collapse;
    margin: 0 auto;
  }

  td,
  th {
    padding: 5px 3px;
    text-align: center;
    white-space: nowrap;
  }
  th.bottom {
    vertical-align: bottom;
  }
  th.suit {
    padding: 3px 0 0 3px;
    img {
      height: 18px;
    }
  }

  .bid {
    border-left: 1px solid black;
  }
  .rank {
    border-right: 1px solid black;
  }

  .rank1 {
    background-color: #c1fcc1;
  }

  .bid,
  .got {
    &.ok {
      background-color: #e1ffe1;
    }
    &.viewer {
      background-color: #fff;
    }
    &.missed {
      background-color: #ffebeb;
    }
  }

  .red {
    color: #de2626;
  }
  .black {
    color: black;
  }

  .score {
    border-left: 1px solid #aaa;
    border-right: 1px solid black;
    &.played {
      background-color: #f9f9f9;
    }
    &.topScore {
      background-color: #c1fcc1;
    }
  }

  .name {
    border: 1px solid black;
  }

  tbody {
    tr {
      border-top: 1px solid grey;
    }
  }

  tfoot {
    tr:first-child {
      border-top: 3px double #000;
    }
    td,
    th {
      padding: 2px 3px;
    }
    .rankLeftSide {
      text-align: right;
    }
  }

  .rules {
    margin: 2em auto 0;
    width: 39em;
    text-align: left;
  }
  // tbody {
  //   tr:nth-child(even) {
  //     background-color: #f7f7f7;
  //   }
  // }
}
