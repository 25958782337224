.Admin .forCopy {
  width: 40em;
}
.Admin .forCopy input {
  text-align: center;
  font-size: 1.3em;
}
.Admin .panel.left {
  text-align: left;
}
.Admin .el-button {
  margin: 3px 0 0 0;
}
