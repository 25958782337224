

































































































































.gameShell {
  height: 100%;
  text-align: center;
  display: grid;
  grid-template-rows: 40px 99fr 1fr;
  grid-template-areas: "a" "b" "c";
  //flex-direction: column;

  #nav {
    grid-area: a;
  }
  .MemberStatus {
    // flex-grow: 1;
    grid-area: c;
  }
  #gameBody {
    // flex-grow: 100;
    grid-area: b;
  }

  .gameBody {
    flex-grow: 1;
    // overflow-y: scroll; // avoid jitter on small screens
    overflow-y: auto;
  }
}
