

































































































































.CardHolder.Standard2 {
  display: inline-block;
  overflow: visible;
  user-select: none;

  .Card {
    height: calc(95vh * 0.2);
    width: calc(95vh * 0.1429);
    //height: calc(312px / 2); // 312
    //width: calc(223px / 2); // 223
    border: 1px solid #666;
    border-radius: 5px;
    box-shadow: 0 0 0 1px #c0c4cc;
  }

  // &.small {
  // }

  &.focused {
    margin-top: -20px;
    transition: all 0.1s;
    flex-shrink: 0;

    .Card {
      box-shadow: 1px 1px 5px 0px #a0a4ac;
    }
  }

  &.select {
    flex-shrink: 0;

    .Card {
      border: 1px solid black;
    }
  }
}
