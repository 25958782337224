











































































































































.KeyTips {
  .el-dialog--center .el-dialog__body {
    padding: 0 25px 30px;
  }

  .key {
    padding: 0 5px 3px;
    background-color: #1f1f1f;
    color: #faf67a;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    min-width: 9px;
  }
  .key + .key {
    margin-left: 1px;
  }

  td,
  th {
    vertical-align: top;
  }
  td.left {
    text-align: right;
    white-space: nowrap;
  }
  thead {
    th {
      text-align: left;
      padding: 1em 0 0 0;
      border-bottom: 1px solid black;
    }
  }
}
