
































































































































































































































































































































































































































.ScoreSheet_Pinochle {
  .dialogFooter {
    text-align: right;
  }
  .NoScores {
    font-size: 1.1em;
    margin: 2em auto;
    text-align: center;
  }

  table {
    border-collapse: collapse;
    margin: 0 auto;
  }

  td,
  th {
    padding: 5px 4px 5px 5px;
    text-align: center;
    white-space: nowrap;
  }
  th.bottom {
    vertical-align: bottom;
  }
  th.suit {
    padding: 3px 0 0 3px;
    img {
      height: 18px;
    }
  }

  .bid {
    border-right: 1px solid black;
  }
  .rank {
    border-right: 1px solid black;
  }
  .info {
    background-color: #f9f9f9;
    span {
      font-size: 75%;
      padding-left: 3px;
    }
  }
  .info2 {
    background-color: #f2f2f2;
    border-right: 1px solid #aaa;
  }
  .info,
  .info2 {
    span {
      font-size: 75%;
      color: #999;
      padding: 0 2px;
    }
  }

  .rank1 {
    background-color: #c1fcc1;
  }

  .bid,
  .got {
    &.ok {
      background-color: #e1ffe1;
    }
    &.viewer {
      background-color: #fff;
    }
    &.missed {
      background-color: #ffebeb;
    }
  }

  .red {
    color: #de2626;
  }
  .black {
    color: black;
  }

  .score {
    border-left: 1px solid #aaa;
    border-right: 1px solid black;
    &.played {
      background-color: #f9f9f9;
    }
  }

  tr.failed {
    .bid {
      background-color: #ffebeb;
      color: #de2626;
    }
  }

  .bidWinner {
    background-color: #e1ffe1;
    &.failed {
      background-color: #ffebeb;
    }
  }
  .topScore {
    background-color: #c1fcc1;
  }

  .name {
    border: 1px solid black;
  }

  .scores {
    tbody {
      tr {
        border-top: 1px solid grey;
      }
    }
  }

  tfoot {
    tr:first-child {
      border-top: 3px double #000;
    }
    td,
    th {
      padding: 2px 3px;
    }
    .rankLeftSide {
      text-align: right;
    }
  }

  .rules {
    margin: 2em auto 0;
    width: 39em;
    text-align: left;
  }
  // tbody {
  //   tr:nth-child(even) {
  //     background-color: #f7f7f7;
  //   }
  // }

  .summary {
    border: 1px solid grey;
    margin: 1em auto;

    thead {
      border-bottom: 1px solid grey;
    }
    th {
      text-align: left;
      border-right: 1px solid grey;
    }
    td:nth-child(2) {
      border-right: 1px solid grey;
    }
    th,
    td {
      padding: 1px 5px;
    }
    tbody {
      tr {
        border-bottom: 1px solid lightgrey;
        &:last-child {
          border-bottom: 1px solid grey;
        }
      }
    }
  }
}
